import "./src/assets/styles/assistant-stylesheet.css";
import "./src/assets/styles/workativ-stylesheet.css";
import "./src/assets/styles/marketplace.css";
import "./src/assets/styles/responsive.css";
import "./src/assets/styles/landing.css";
import "./src/assets/styles/style.css";
import "./src/assets/styles/slick.css";
import "./src/assets/styles/slick-theme.css";
import "./src/assets/styles/brevo.css";

import "./src/assets/styles/bootstrap.min.css";
import mixpanel from 'mixpanel-browser';
import Cookies from 'js-cookie'


export const onClientEntry = () => {
  // const whyDidYouRender = require("@welldone-software/why-did-you-render");
  // whyDidYouRender(React, {
  //   trackAllPureComponents: true,
  //   trackHooks: true
  // });

  if (document.getElementById("loader-wrapper"))
    document.getElementById("loader-wrapper").style.display = "flex";
  document.getElementsByTagName("html")[0].style.visibility = "hidden";
};


export const onPreRouteUpdate = () => {
  if (document.getElementById("loader-wrapper"))
    document.getElementById("loader-wrapper").style.display = "flex";
  document.getElementsByTagName("html")[0].style.visibility = "hidden";
};


export const onRouteUpdateDelayed = () => {
  if (document.getElementById("loader-wrapper"))
    document.getElementById("loader-wrapper").style.display = "flex";
};


export const onRouteUpdate = () => {
  setTimeout(() => {
    document.getElementsByTagName("html")[0].style.visibility = "visible";
    document.getElementById("loader-wrapper").style.display = "none";
  }, 0);
  const thirdParty = Cookies.get('gatsby-gdpr-google-tagmanager')
  console.log("mixpanel obj outside",mixpanel, thirdParty)
  if (thirdParty.toString() === 'true') {

    mixpanel.init("7c8bdb39a58feedea88d1c4ce2343203")
    console.log("Mixpanel initialised")
    mixpanel.track_pageview();
  }
  if (typeof window !== `undefined`) {
    window.scrollTo(0, 0);
    window.mixpanel_custom = mixpanel
    // console.log("mixpanel window obj inside",window.mixpanel_custom)
  }
};

export const shouldUpdateScroll = (args) => {
  return false;
};
